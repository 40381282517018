.homeworkContainer {
  padding: 20px;
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
}

.homeworkWrapper {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.homeworkList {
  padding: 20px;
  background-color: #f9f9f9;
}

.homeworkItem {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #ffffff;
  transition: background-color 0.3s ease;
}

.homeworkItem:hover {
  background-color: #f0f0f0;
}

.submitButton, .backButton {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #4CAF50;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitButton:hover, .backButton:hover {
  background-color: #45a049;
}

h4, h5 {
  font-size: 18px;
  color: #333;
}

label {
  display: block;
  margin: 10px 0;
  cursor: pointer;
}

input[type="radio"] {
  margin-right: 10px;
}
