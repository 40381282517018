.header {
  background-color: #ffffff; /* Dark background */
  color: #fff; /* White text */

  padding: 10px 0;
  transition: background-color 0.3s ease;
}

.header:hover {
  background-color: #ffffff; /* Slightly lighter on hover */
}

.logo {
  display: flex;
  align-items: center;
}

.logo-image {
  width: 147px; /* Adjust size */
  height: auto;
  margin-left: 0px;
}

.nav__list {
  display: flex;
  gap: 30px;
}

.nav__item {
  position: relative;
}

.nav__item a {
  text-decoration: none;
  color: #000000;
  font-weight: 500;
  padding: 8px 15px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.nav__item a:hover {
  background-color: #007bff; /* Blue background on hover */
  color: #fff; /* Keep text white */
}

.mobile__menu {
  display: none; /* Hide mobile menu by default */
}

@media (max-width: 768px) {
  .nav__menu {
      display: none; /* Hide the desktop menu */
  }

  .nav__menu.active__menu {
      display: block; /* Show on toggle */
      position: absolute;
      top: 60px; /* Position it below the header */
      left: 0;
      right: 0;
      background-color: #ffffff;
      border-radius: 0 0 10px 10px;
      z-index: 1000;
      transition: opacity 0.3s ease;
  }

  .mobile__menu {
      display: block; /* Show mobile menu on small screens */
  }

  .nav__list {
      flex-direction: column; /* Stack items vertically */
      gap: 10px;
  }


.login-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.login-card {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 400px; /* Fixed width for the card */
}

.login-card h3 {
  margin-bottom: 15px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-actions {
  display: flex;
  justify-content: space-between;
}

.btn-employee {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50%; /* Circular button */
  transition: background 0.3s, transform 0.3s; /* Smooth transitions */
}


.btn-language {
  background-color: #007bff; /* Blue background */
  color: white; /* White text */
  border: none; /* No border */
  padding: 8px 12px; /* Padding */
  border-radius: 5px; /* Slightly rounded corners */
  cursor: pointer; /* Pointer cursor */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transitions */
}

.btn-language:hover {
  background-color: #0056b3; /* Darker blue on hover */
  transform: scale(1.05); /* Slightly enlarge on hover */
}

}
.notifications {
  margin-top: 15px;
  position: relative;
}

.notification {
  padding: 12px 20px;
  background-color: #f8f9fa; /* Light gray background (adjust as needed) */
  border: 1px solid #ced4da; /* Light border */
  border-radius: 8px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: transform 0.2s;
}

.notification:hover {
  transform: translateY(-2px); /* Lift effect on hover */
}

.notification.new {
  border-left: 5px solid #007bff; /* Blue highlight for new notifications */
  background-color: #e7f3ff; /* Light blue background for new notifications */
}

button {
  background: none;
  border: none;
  color: #007bff; /* Blue text */
  cursor: pointer;
  font-weight: bold;
  transition: color 0.2s;
}

button:hover {
  color: #0056b3; /* Darker blue on hover */
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .notification {
    flex-direction: column;
    align-items: flex-start;
  }

  button {
    margin-top: 5px;
  }
}