.container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.title {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
    font-family: 'Arial', sans-serif;
    font-size: 24px;
}

.form {
    display: flex;
    flex-direction: column;
}

.formGroup {
    margin-bottom: 15px;
}

label {
    font-weight: bold;
    margin-bottom: 5px;
    color: #555;
}

.input,
.textarea,
.fileInput {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.textarea {
    height: 100px;
}

.fileInput {
    padding: 3px;
}

.submitButton {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submitButton:hover {
    background-color: #0056b3;
}

.status {
    text-align: center;
    margin-top: 20px;
    color: green;
}

.error {
    text-align: center;
    margin-top: 20px;
    color: red;
}