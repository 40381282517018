/* General body styling */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f0f2f5;
  margin: 0;
  padding: 0;
}

/* Dashboard layout */
.dashboard {
  display: flex;
  height: 100vh;
}

/* Sidebar styling */
.sidebar {
  width: 250px; /* Fixed width for the sidebar */
  background-color: #34495e; /* Darker background for better contrast */
  color: white;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column; /* Align items vertically */
  justify-content: space-between; /* Add space between items */
}

.sidebar h2 {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  flex-grow: 1; /* Allow the list to grow */
}

.sidebar ul li {
  margin: 15px 0;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.sidebar ul li:hover {
  background-color: #2c3e50; /* Lighter background on hover */
}

.main-content {
  flex-grow: 1; /* Allow the main content to fill the available space */
  padding: 20px;
  background-color: #ecf0f1; /* Light background for contrast */
  overflow-y: auto; /* Enable scrolling if content overflows */
}

/* Headings */
h2, h3 {
  color: #343a40;
  margin-bottom: 20px;
}

/* Form group styling */
.form-group {
  margin-bottom: 20px;
}

/* Label styling */
label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #343a40;
}

/* Input and select styling */
.styled-select,
.styled-input,
.styled-file-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.styled-select:focus,
.styled-input:focus {
  border-color: #007bff;
  outline: none;
}

/* Button styling */
.btn-submit {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
  width: 100%; /* Full width */
}

.btn-submit:hover {
  background-color: #0056b3;
}

/* Success message styling */
.success-message {
  margin-top: 20px;
  color: #28a745;
  font-weight: bold;
}

/* Loading message styling */
.loading {
  text-align: center;
  margin-top: 50px;
  font-size: 1.5rem;
}

/* Table styling */
.user-table,
.enrolled-users-table,
.grades-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.user-table th,
.enrolled-users-table th,
.grades-table th {
  background-color: #343a40;
  color: #fff;
  padding: 10px;
}

.user-table td,
.enrolled-users-table td,
.grades-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

/* Zebra striping for grades table */
.grades-table th {
  background-color: #4CAF50; /* Green background */
  color: white; /* White text */
}

.grades-table tr:nth-child(even) {
  background-color: #f2f2f2; /* Zebra striping */
}

.grades-table tr:hover {
  background-color: #ddd; /* Highlight on hover */
}

.grades-table td {
  font-size: 14px; /* Font size */
}

/* Specific styles for Manage Courses */
.manage-courses {
  background-color: #e9ecef; /* Light grey background for Manage Courses */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.manage-courses h3 {
  margin-bottom: 15px;
  color: #343a40;
}

/* File upload form */
.upload-form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.upload-form label {
  margin-bottom: 5px;
}

.upload-form input[type="file"] {
  margin-bottom: 15px;
}

/* Updated styles for Drag-and-Drop */
.upload-area {
  border: 2px dashed #007bff;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
  transition: background-color 0.3s;
  cursor: pointer;
  position: relative;
}

.upload-area.dragging {
  background-color: #e9ecef;
}

.upload-area p {
  margin: 0;
  color: #007bff;
  font-weight: bold;
}

/* General loading message */
.loading {
  text-align: center;
  margin-top: 20px;
  font-size: 1.2rem;
  color: #007bff; /* Customize to your preference */
}

/* User Messages Table */
.messages-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.messages-table th, .messages-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: left;
}

.messages-table th {
  background-color: #3498db; /* Blue background for headers */
  color: white;
  font-weight: bold;
}

.messages-table tr:nth-child(even) {
  background-color: #f2f2f2; /* Light gray for even rows */
}

.messages-table tr:hover {
  background-color: #e1e1e1; /* Light gray on hover */
}

.messages-table p {
  margin: 10px 0;
  color: #555; /* Text color for empty messages */
}

h2 {
  color: #2c3e50; /* Darker color for section headers */
}
