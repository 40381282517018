/* contactUs.css */
.contact-us-container {
  margin: 20px auto;
  max-width: 900px; /* Wider layout */
  padding: 30px;
  background-color: #ffffff; /* White background for contrast */
  border-radius: 15px; /* More rounded corners */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Deeper shadow for depth */
}

.contact-us-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px; /* Larger title */
  color: #333; /* Darker text for better readability */
}

.contact-us-layout {
  display: flex;
  flex-direction: column; /* Stack elements on mobile */
  gap: 20px; /* Space between sections */
}

.contact-form {
  background-color: #f7f7f7; /* Light gray background for the form */
  padding: 20px;
  border-radius: 10px; /* Rounded corners for the form */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.support-contacts {
  width: 100%; /* Full width on mobile */
  background-color: #f7f7f7; /* Match form background */
  padding: 20px;
  border-radius: 10px; /* Rounded corners for contacts */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.support-contacts h3 {
  margin-bottom: 10px;
  font-size: 20px; /* Slightly larger heading */
}

.support-contacts ul {
  list-style-type: none;
  padding: 0;
}

.support-contacts li {
  margin: 5px 0;
  color: #555; /* Softer text color */
}

.submit-button {
  width: 100%; /* Full-width button */
  padding: 10px;
  background-color: #007bff; /* Primary button color */
  color: #fff; /* White text */
  border: none;
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  font-weight: bold;
  transition: background-color 0.3s; /* Smooth transition */
}

.submit-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .contact-us-layout {
      flex-direction: column; /* Stack elements on smaller screens */
  }

  .support-contacts {
      width: 100%; /* Full width for support contacts on mobile */
  }

  .contact-us-title {
      font-size: 20px; /* Slightly smaller title on mobile */
  }

  .submit-button {
      font-size: 16px; /* Larger text for button on mobile */
  }
}