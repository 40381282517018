.manage-adds {
    padding: 20px;
    background-color: #f9f9f9; /* Light background for better readability */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  }
  
  .manage-adds h2 {
    font-size: 1.8rem; /* Larger font for the heading */
    margin-bottom: 15px; /* Space below the heading */
  }
  
  .manage-adds input {
    width: 100%; /* Full width */
    padding: 10px; /* Padding for input */
    margin-bottom: 10px; /* Space below the input */
    border: 1px solid #ccc; /* Light border */
    border-radius: 4px; /* Rounded corners */
    font-size: 1rem; /* Font size */
    direction: rtl; /* Right-to-left direction for Arabic text */
  }
  
  .manage-adds button {
    padding: 10px 15px; /* Padding for button */
    background-color: #007bff; /* Primary color */
    color: white; /* White text */
    border: none; /* No border */
    border-radius: 4px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 1rem; /* Font size */
    transition: background-color 0.3s; /* Smooth transition */
  }
  
  .manage-adds button:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }
  
  .manage-adds ul {
    list-style-type: none; /* Remove default list styles */
    padding: 0; /* Remove padding */
  }
  
  .manage-adds li {
    display: flex; /* Flexbox for alignment */
    justify-content: space-between; /* Space between text and button */
    padding: 10px; /* Padding for list items */
    border-bottom: 1px solid #eee; /* Light border for separation */
  }
  
  .manage-adds li button {
    background-color: #dc3545; /* Red for delete */
  }
  
  .manage-adds li button:hover {
    background-color: #c82333; /* Darker red on hover */
  }