/* AudioPlayerPage.module.css */
.audioPlayerPage {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .audioPlayerPage h2 {
    color: #333;
  }
  
  .audioPlayerPage ul {
    list-style-type: none;
    padding: 0;
  }
  
  .audioPlayerPage li {
    margin: 10px 0;
  }