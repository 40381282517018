.paymentPage {
    padding: 30px;
    max-width: 800px;
    margin: auto;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
  }
  
  h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .detailsContainer {
    display: flex;
    justify-content: space-between; /* Space between columns */
    margin-bottom: 20px;
  }
  
  .leftColumn, .middleColumn, .rightColumn {
    flex: 1; /* Equal width for all columns */
    padding: 10px;
  }
  
  .leftColumn {
    border-right: 1px solid #e0e0e0; /* Divider between left and middle */
  }
  
  .middleColumn {
    border-right: 1px solid #e0e0e0; /* Divider between middle and right */
    text-align: center; /* Center align the text */
  }
  
  p {
    margin: 10px 0;
    color: #555;
  }
  
  strong {
    color: #007bff;
  }
  
  .confirmButton {
    display: block;
    width: 100%;
    padding: 15px;
    margin-top: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .confirmButton:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }
  
  .confirmButton:active {
    transform: translateY(0);
  }
  .notesContainer {
    margin-top: 20px; /* Space above the notes section */
    text-align: center; /* Center align the notes */
  }
  
  .notesContainer h3 {
    margin-bottom: 10px;
    color: #ff0000;
  }

  .languageButton {
    margin: 10px 0;
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .languageButton:hover {
    background-color: #0056b3;
  }

  @media (max-width: 600px) {
    .paymentPage {
      padding: 15px;
    }
  
    h2 {
      font-size: 1.5rem;
    }
  
    .detailsContainer {
      flex-direction: column; /* Stack columns vertically */
    }
  
    .leftColumn, .middleColumn, .rightColumn {
      border-right: none; /* Remove borders for stacked layout */
      border-bottom: 1px solid #e0e0e0; /* Add bottom border for separation */
    }
  
    .confirmButton {
      padding: 12px;
    }
  
    .notesContainer h3 {
      font-size: 1.2rem;
    }
  }