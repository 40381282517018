.messagesContainer {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  h3 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .messageForm {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .messageInput {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
    resize: none;
    height: 80px; /* Fixed height for the message input */
  }
  
  .sendButton {
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .sendButton:hover {
    background-color: #0056b3;
  }
  
  .messageList {
    list-style-type: none;
    padding: 0;
  }
  
  .messageCard {
    background-color: #fff;
    border: 1px solid #eaeaea;
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 10px;
    transition: box-shadow 0.3s ease;
  }
  
  .messageCard:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  strong {
    display: block;
    margin-bottom: 5px;
    color: #555;
  }