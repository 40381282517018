

/* Choose Content Styles */
.choose__content,
.choose__courses-card {
  padding: 40px 30px; /* Same padding for both sections */
  background-color: #ffffff; /* White background for both sections */
  border-radius: 15px; /* Rounded corners */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  margin-top: 20px; /* Space between sections */
}

/* Course Image Styles */
.course-image {
  border-radius: 15px; /* Rounded corners */
  width: 100%; /* Full width of the container */
  height: 200px; /* Set a specific height */
  object-fit: cover; /* Maintain aspect ratio while covering the area */
  transition: transform 0.3s ease; /* Smooth scaling effect */
}

.course-image:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
}

/* Single Course Styles */
.single__course {
  padding: 20px;
  background-color: #f9f9f9; /* Light background for courses */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: transform 0.3s ease; /* Smooth scaling effect */
}

.single__course:hover {
  transform: translateY(-5px); /* Lift effect on hover */
}

.course__info {
  margin-top: 15px;
  text-align: left; /* Left-align course info */
}