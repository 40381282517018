.courseDetail {
  display: flex;
  font-family: Arial, sans-serif;
}

.sidebar {
  width: 25%;
  background-color: #f4f4f4;
  padding: 20px;
  border-right: 2px solid #ccc;
  position: sticky;
  top: 0;
  height: 100vh; /* Make sidebar full height */
}

.sidebar h2 {
  color: #333;
}

.navButton {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  margin: 10px 0;
  cursor: pointer;
  transition: background-color 0.3s;
}

.navButton:hover {
  background-color: #0056b3;
}

.toggleButton {
  background-color: #6c757d; /* Gray color for toggle button */
  color: white;
  border: none;
  padding: 10px;
  margin: 20px 0; /* Add margin for spacing */
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggleButton:hover {
  background-color: #5a6268; /* Darker gray on hover */
}

.videoList {
  margin-top: 20px;
}

.videoWrapper {
  margin-bottom: 10px; /* Space between sidebar videos */
}

.sidebarVideo {
  width: 100%; /* Full width in the sidebar */
  border-radius: 8px;
  cursor: pointer; /* Change cursor to pointer */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
  transition: transform 0.3s; /* Smooth transition for hover effect */
}

.sidebarVideo:hover {
  transform: scale(1.02); /* Slightly enlarge on hover */
}

.mainContent {
  width: 75%;
  padding: 20px;
}

.videoContainer {
  position: relative;
}

.videoWrapper {
  position: relative;
}

.watermark {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white; /* Adjust color as needed */
  opacity: 0.7; /* Adjust transparency */
  font-size: 24px; /* Adjust size */
  pointer-events: none; /* Allow clicks to pass through */
}

.mainVideo {
  width: 100%; /* Full width for main video */
  height: auto; /* Maintain aspect ratio */
  max-height: 600px; /* Set a maximum height for larger screens */
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
  transition: transform 0.3s; /* Smooth transition for hover effect */
}

.mainVideo:hover {
  transform: scale(1.02); /* Slightly enlarge on hover */
}

.nextButton {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff; /* Example color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.nextButton:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.documentContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Space between document viewers */
}

.documentWrapper {
  flex: 1 1 45%; /* Adjusts the size of document containers */
  max-width: 100%;
}

.document {
  width: 100%;
  height: 400px; /* Adjust height as needed */
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Add this at the end of your existing CSS file */

@media (max-width: 768px) {
  .courseDetail {
    flex-direction: column; /* Stack sidebar and main content */
  }

  .sidebar {
    width: 100%; /* Full width for sidebar */
    height: auto; /* Allow height to adjust */
    border-right: none; /* Remove right border */
    border-bottom: 2px solid #ccc; /* Add bottom border for separation */
  }

  .mainContent {
    width: 100%; /* Full width for main content */
    padding: 10px; /* Reduce padding */
  }

  .videoWrapper {
    margin-bottom: 15px; /* Increase space between videos */
  }

  .mainVideo {
    max-height: 300px; /* Reduce max height for mobile */
  }

  .documentContainer {
    flex-direction: column; /* Stack documents vertically */
  }

  .documentWrapper {
    flex: 1 1 100%; /* Full width for document containers */
  }

  .navButton, .toggleButton, .nextButton {
    width: 100%; /* Full width for buttons */
    padding: 15px; /* Increase padding for easier tapping */
  }

  .sidebarVideo {
    height: 150px; /* Set a fixed height for sidebar videos */
  }

  .watermark {
    font-size: 18px; /* Reduce watermark size */
  }
}