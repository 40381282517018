.examsContainer {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  
  .examsContainer h4 {
    margin-bottom: 10px;
  }
  
  .examsContainer h5 {
    margin-top: 20px;
    color: green;
  }
  
  .examsContainer label {
    display: block;
    margin: 5px 0;
  }
  
  .examsContainer button {
    margin-top: 10px;
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .examsContainer button:hover {
    background-color: #0056b3;
  }

  @media (max-width: 600px) {
    .examsContainer {
      padding: 10px;
    }
  
    .examsContainer h4 {
      font-size: 1.2rem;
    }
  
    .examsContainer h5 {
      font-size: 1rem;
    }
  
    .examsContainer button {
      width: 100%; /* Full width for buttons */
      padding: 12px;
    }
  }