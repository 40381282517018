/* AddHomework.css */

body {
    font-family: 'Arial', sans-serif;
    background-color: #f0f4f8;
    color: #333;
  }
  
  .add-homework-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .add-homework-form {
    margin-bottom: 20px;
  }
  
  .form-title {
    text-align: center;
    color: #007bff;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #007bff;
    border-radius: 5px;
    transition: border-color 0.3s;
  }
  
  .form-group input:focus,
  .form-group select:focus {
    border-color: #0056b3;
    outline: none;
  }
  
  
  
  button:hover {
    background-color: #0056b3;
  }
  
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .question-block {
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .radio-buttons {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }
  
  .radio-label {
    padding: 5px 10px;
    border: 1px solid #007bff;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
    transition: background-color 0.3s;
  }
  
  .radio-label.selected {
    background-color: #007bff;
    color: white;
  }
  
  .radio-label:hover {
    background-color: #e6f0ff;
  }
  
  .radio-label input {
    display: none; /* Hide the default radio button */
  }
  
  .homework-list {
    list-style-type: none;
    padding: 0;
  }
  
  .homework-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #eef;
  }
  
  .btn-delete {
    background-color: #ff4d4d;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px 10px;
  }
  
  .btn-delete:hover {
    background-color: #e60000;
  }