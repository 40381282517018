/* LoginEmployee.module.css */

.authContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Full viewport height */
  background-image: linear-gradient(to right, #f5f5f5, #dbdbdb); /* Gradient background effect */
  padding: 20px;
}

.authContainer h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333; /* Dark text color */
}

.form {
  background-color: #fff; /* White background for the form */
  padding: 30px;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  width: 100%;
  max-width: 400px; /* Max width for the form */
}

.form div {
  margin-bottom: 15px; /* Space between form fields */
}

.form label {
  display: block;
  margin-bottom: 5px; /* Space between label and input */
  font-weight: bold; /* Bold labels */
}

.form input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc; /* Light border */
  border-radius: 4px; /* Rounded input corners */
  font-size: 16px;
}

.form input:focus {
  border-color: #007bff; /* Highlight border on focus */
  outline: none; /* Remove default outline */
}

.form button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}
/* Button styles in LoginEmployee.module.css */

.loginButton {
  background-color: #007bff; /* Primary button color */
  color: #fff; /* Text color */
  border: none; /* Remove default border */
  border-radius: 4px; /* Rounded corners */
  padding: 10px 15px; /* Padding for the button */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.loginButton:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.loginButton:disabled {
  background-color: #c0c0c0; /* Gray background when disabled */
  cursor: not-allowed; /* Not-allowed cursor on disabled state */
}
.imageContainer {
  display: flex; /* Use flexbox for centering */
  justify-content: center; /* Center the image horizontally */
  align-items: center; /* Center the image vertically */
  height: 100%; /* Full height to align with the form */
}

.image {
  max-width: 100%; /* Make image responsive */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px; /* Optional: rounded corners */
}
.errorMessage {
  color: red; /* Red color for error messages */
  margin-bottom: 15px; /* Space below the error message */
  font-weight: bold; /* Bold text */
}


@media (max-width: 600px) {
  .authContainer {
    padding: 10px; /* Less padding on small screens */
  }

  .form {
    padding: 20px; /* Less padding for the form */
  }
}