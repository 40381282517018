.send-message-container {
    padding: 30px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    max-width: 600px;
    margin: 40px auto;
    transition: transform 0.3s;
}

.send-message-container:hover {
    transform: scale(1.02);
}

.send-message-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    font-family: 'Arial', sans-serif;
    font-weight: bold;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #555;
}

.form-group select,
.form-group textarea,
.form-group input {
    width: 100%;
    padding: 12px;
    border: 2px solid #007bff;
    border-radius: 6px;
    font-size: 16px;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.form-group select:focus,
.form-group textarea:focus,
.form-group input:focus {
    border-color: #0056b3;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
}

.form-group textarea {
    resize: vertical;
    height: 100px;
}

.btn-submit {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 12px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 18px;
    width: 100%;
    transition: background-color 0.3s, transform 0.3s;
}

.btn-submit:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}

.success-message {
    margin-top: 15px;
    color: green;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
}
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.loading-container img {
    width: 50px; /* Adjust size as needed */
    height: 50px; /* Adjust size as needed */
}