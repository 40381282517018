/* userDashboard.css */

/* Global container styles for the user dashboard */
.user-dashboard {
    display: flex;
    height: 100vh;
    background-color: #f4f7fc;
    font-family: Arial, sans-serif;
  }
  
  .sidebar {
    width: 250px;
    background-color: #2c3e50;
    color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 4px 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .sidebar h2 {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .sidebar li {
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .sidebar li:hover {
    background-color: #34495e;
  }
  
  .btn-logout {
    margin-top: 20px;
    padding: 10px;
    background-color: #e74c3c;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .btn-logout:hover {
    background-color: #c0392b;
  }
  
  .main-content {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
    background-color: #fff;
    border-left: 2px solid #ddd;
  }
  
  .main-content h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #34495e;
  }
  
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 24px;
    color: #3498db;
  }
  
  /* Specific styles for individual sections */
  .manage-courses, .manage-exams, .enrolled-users, .course-videos, .add-files, .homework {
    background-color: #ecf0f1;
    padding: 20px;
    border-radius: 8px;
    margin-top: 20px;
  }
  
  .manage-courses h3, .manage-exams h3, .enrolled-users h3, .course-videos h3, .add-files h3, .homework h3 {
    color: #2c3e50;
  }
  
  .manage-courses ul, .manage-exams ul, .enrolled-users ul, .course-videos ul, .add-files ul, .homework ul {
    list-style: none;
    padding: 0;
  }
  
  .manage-courses li, .manage-exams li, .enrolled-users li, .course-videos li, .add-files li, .homework li {
    margin-bottom: 10px;
    padding: 10px;
    background-color: #bdc3c7;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .manage-courses li:hover, .manage-exams li:hover, .enrolled-users li:hover, .course-videos li:hover, .add-files li:hover, .homework li:hover {
    background-color: #95a5a6;
  }
  