/* CourseVideos.module.css */
.videoContainer {
    margin-top: 20px;
  }
  
  .mainVideoWrapper {
    margin-bottom: 20px;
  }
  
  .mainVideo {
    width: 100%;
    max-width: 800px;
    height: auto;
  }
  
  .videoList {
    list-style-type: none;
    padding: 0;
  }
  
  .videoItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .videoName {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
  }
  
  .videoName:hover {
    color: darkblue;
  }
  
  .deleteButton {
    background-color: red;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .deleteButton:hover {
    background-color: darkred;
  }