.company-section {
  background: linear-gradient(135deg, #6a11cb, #2575fc); /* Gradient background */
  padding: 15px 0; /* Reduced padding for a tighter feel */
  text-align: center; /* Center text within the section */
}

.text-row {
  display: flex; /* Use flexbox for horizontal layout */
  justify-content: center; /* Center the texts */
  flex-wrap: wrap; /* Allow wrapping */
  margin: 5px 0; /* Minimal margin around the text row */
}

.text-item {
  color: #ffffff; /* White text for contrast */
  font-size: 1.25rem; /* Smaller font size for a more compact look */
  font-weight: bold; /* Bold text */
  margin: 5px; /* Space between texts */
  padding: 8px 15px; /* Further reduced padding around text */
  border-radius: 6px; /* Smaller rounded corners */
  background-color: rgba(255, 255, 255, 0.2); /* Semi-transparent background */
  backdrop-filter: blur(5px); /* Blur effect for depth */
  transition: transform 0.3s, background-color 0.3s; /* Smooth transitions */
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2); /* Reduced shadow for subtle effect */
}

.text-item:hover {
  background-color: rgba(255, 255, 255, 0.4); /* Change background on hover */
  transform: scale(1.02); /* Slightly enlarge on hover */
}