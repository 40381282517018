.add-audio-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 20px auto;
}

.add-audio-container h2 {
    font-size: 24px;
    margin-bottom: 16px;
    color: #333;
}

.add-audio-container form {
    display: flex;
    flex-direction: column;
}

.add-audio-container input[type="file"] {
    margin-bottom: 16px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.add-audio-container button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.add-audio-container button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
}

.add-audio-container div {
    margin-top: 16px;
    font-size: 14px;
    color: green;
}