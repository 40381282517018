.userProfile {
  display: flex;
  font-family: Arial, sans-serif;
}

.sidebar {
  width: 250px;
  padding: 20px;
  background-color: #f4f4f4;
  border-right: 1px solid #ddd;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
}

.navButton {
  margin: 10px 0;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.navButton:hover {
  background-color: #0056b3;
}

.mainContent {
  padding: 20px;
  flex-grow: 1;
}

.courseList {
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.courseCard {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.courseCard:hover {
  transform: translateY(-5px);
}

.courseImage {
  width: 100%;
  height: 150px; /* Fixed height for uniformity */
  object-fit: cover; /* Ensures image covers the area nicely */
}

.courseDetails {
  padding: 15px;
}

.enrollButton {
  margin-top: 10px;
  padding: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.enrollButton:hover {
  background-color: #218838;
}
.videoList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.videoCard {
  background: #f8f9fa;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s, box-shadow 0.2s;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.videoCard:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.videoPlayer {
  width: 100%;
  height: auto;
  border-radius: 0 0 8px 8px;
}

h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

h4 {
  font-size: 1.2rem;
  margin: 10px 0;
  text-align: center;
  color: #333;
}

@media (max-width: 600px) {
  .userProfile {
    flex-direction: column; /* Stack sidebar and main content */
  }

  .sidebar {
    width: 100%; /* Full width for sidebar */
    border-right: none; /* Remove right border */
    border-bottom: 1px solid #ddd; /* Add bottom border */
  }

  .mainContent {
    padding: 15px;
  }

  .courseList {
    grid-template-columns: 1fr; /* Single column layout */
  }

  .enrollButton {
    padding: 12px;
  }
}