.hero-section {
   padding: 60px 0;
   background-color: #f8f9fa; /* Light background for better contrast */
 }
 
 .hero__content {
   text-align: left; /* Align text to the left for better readability */
   display: flex;
   flex-direction: column; /* Stack elements vertically */
   justify-content: center; /* Center content vertically */
   height: 100%; /* Full height for proper alignment */
 }
 
 .hero-logo {
  max-width: 100%;
  height: auto;
  margin-top: 0px; /* تقليل المسافة العلوية */
}
 
 @media (max-width: 768px) {
   .hero-section {
     padding: 30px 0; /* Reduce padding on smaller screens */
   }
 
   .hero__content {
     text-align: center; /* Center text on mobile for better presentation */
     align-items: center; /* Center align items in flex container */
   }
 
   .hero-logo {
     margin-top: 10px; /* Reduce margin on mobile for tighter spacing */
   }
 
   h2 {
     font-size: 1.5rem; /* Adjust title size for smaller screens */
   }
 
   p {
     font-size: 1rem; /* Adjust paragraph size for readability */
   }
 
   button {
     width: 100%; /* Make buttons full width on mobile */
     margin-top: 10px; /* Space between buttons */
   }
 }