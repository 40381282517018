.footer {
  background: linear-gradient(135deg, rgba(226, 248, 248, 0.9), rgba(173, 216, 230, 0.9));
  padding: 40px 0;
  color: #333;
  font-family: 'Arial', sans-serif;
}

.footer h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #0a2b1e;
}

.footer .follows i {
  font-size: 1.8rem;
  transition: transform 0.3s;
}

.follows span {
  margin-right: 20px;
}

.follows span a:hover i {
  transform: scale(1.1);
}

.link__list .link__item {
  background: transparent;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.link__list a {
  text-decoration: none;
  color: #0a2b1e;
  font-weight: 600;
  transition: color 0.3s;
}

.link__list a:hover {
  color: #007bff;
}

.footer__copyright {
  text-align: center;
  margin-top: 20px;
  font-size: 0.9rem;
  color: #666;
}

.footer .btn {
  background-color: #0a2b1e;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.footer .btn:hover {
  background-color: #005f4f;
}