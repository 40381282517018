.manage-support-contacts {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .manage-support-contacts h2,
  .manage-support-contacts h3 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .manage-support-contacts form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .manage-support-contacts input[type="text"],
  .manage-support-contacts input[type="email"] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .manage-support-contacts button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .manage-support-contacts button:hover {
    background-color: #0056b3;
  }
  
  .manage-support-contacts ul {
    list-style-type: none;
    padding: 0;
  }
  
  .manage-support-contacts li {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
    background-color: #fff;
  }