/* About Section Styles */
section {
    padding: 60px 0;
    background-color: #4a90e2; /* Example background color (blue) */
    background-image: linear-gradient(to right, #ffffff, #b8b8b8); /* Gradient background effect */
  }
  
  .about__img img {
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
  
  .about__content {
    padding: 20px;
  }
  
  .about__content h2 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
    font-weight: 600;
  }
  
  .about__content p {
    font-size: 1.1rem;
    color: #555;
    line-height: 1.6;
    margin-bottom: 30px;
  }
  
  .about__counter {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .single__counter {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s;
  }
  
  .single__counter:hover {
    transform: translateY(-5px);
  }
  
  .counter {
    font-size: 2rem;
    color: #007bff; /* Bootstrap primary color */
    font-weight: bold;
  }
  
  .counter__title {
    margin-top: 10px;
    font-size: 1rem;
    color: #777;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .about__content h2 {
      font-size: 2rem;
    }
  
    .about__content p {
      font-size: 1rem;
    }
  
    .counter {
      font-size: 1.5rem;
    }
  }