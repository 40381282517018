.upload-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
  }
  
  .upload-form {
    display: flex;
    flex-direction: column;
  }
  
  .upload-form input[type="file"] {
    margin: 10px 0;
  }
  
  .upload-button {
    padding: 10px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .upload-button:hover {
    background-color: #218838;
  }
  
  .loading-icon {
    display: block;
    margin: 15px auto;
    width: 50px; /* Adjust the size as needed */
    height: 50px; /* Adjust the size as needed */
  }